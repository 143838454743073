import React from 'react'
import Button from '@components/Button'
import getFileURL from '@helpers/misc/getFileURL'
import nl2br from '@helpers/misc/nl2br'
import {useRouter} from 'next/router'

import styles from './styles.module.css'

const renderImage = function (image, alt) {
  if (!image) return
  const url = getFileURL(image)
  return (
    <div className={styles.imgContainer}>
      <img className={styles.image} src={url} alt={alt} title={alt} />
    </div>
  )
}

export default function FullImageWithMessage({image, title, text, rightImage, url, buttonText}) {
  const router = useRouter()

  return (
    <div className={rightImage ? styles.containerRight : styles.container}>
      {renderImage(image, title)}
      <div className={styles.textBox}>
        <div className={styles.boxInner}>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{nl2br(text)}</div>
          {url ? (
            <Button onClick={() => router.push(url)} primary>
              {buttonText}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}
